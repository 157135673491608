<template>
  <v-container fluid class="pa-0">
    <v-row no-gutters>
      <v-col cols="12" class="login-part d-flex align-center justify-center">
        <v-row no-gutters class="align-start">
          <v-col
            cols="12"
            class="login-part d-flex align-center justify-center flex-column"
          >
            <div class="pt-sm-0">
              <v-container>
                <v-row class="flex-column">
                  <v-card color="background" elevation="0" max-width="400">
                    <v-col class="text-center">
                      <img
                        v-if="$vuetify.theme.dark"
                        src="../assets/ssencial-logo-white.svg"
                        width="40px"
                        alt="ssencial"
                      />
                      <img
                        v-else
                        src="../assets/ssencial-logo.svg"
                        width="40px"
                        alt="ssencial"
                      />
                    </v-col>
                    <v-col>
                      <p class="text-h4 text-center font-weight-medium my-5">
                        Recuperación de contraseña
                      </p>
                      <p class="ma-0">
                        Ingresa tu <strong>correo electrónico </strong>que
                        usaste para registrarte. Te enviaremos un mesaje con un
                        enlace para restablecer tu contraseña.
                      </p>
                    </v-col>
                    <v-form ref="log" v-model="valid" lazy-validation>
                      <v-col class="pb-0">
                        <v-text-field
                          outlined
                          type="email"
                          ref="email"
                          v-model="email"
                          :rules="emailRules"
                          label="Correo"
                          @keyup.enter.native="recoverPassword"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col class="d-flex justify-end pt-0">
                        <v-btn
                          class="text-capitalize"
                          block
                          color="primary"
                          :loading="isFetching"
                          @click="recoverPassword"
                        >
                          Enviar
                        </v-btn>
                      </v-col>
                      <v-col class="text-center pt-0">
                        <p class="ma-0">
                          Si aún necesitas ayuda, ponte en contacto con el
                          <a
                            href="http://ayuda.ssencial.com"
                            target="_blank"
                            class="ml-1 text-decoration-none"
                            >Soporte de SSENCIAL</a
                          >
                        </p>
                      </v-col>
                      <v-col class="text-center pt-0">
                        <p class="primary--text hover-link ma-0" @click="login">
                          Ir al inicio de sesion
                        </p>
                      </v-col>
                    </v-form>
                  </v-card>
                </v-row>
              </v-container>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import { emailValid } from "@/services/ValidatorService";

export default {
  name: "Login",
  data() {
    return {
      valid: false,
      email: "",
      emailRules: [emailValid],
      isFetching: false
    };
  },
  methods: {
    ...mapActions("user", ["resetPasswordAction"]),
    async recoverPassword() {
      if (this.$refs.log.validate()) {
        this.isFetching = true;
        await this.resetPasswordAction(this.email);
        this.isFetching = false;
      }
    },
    login() {
      this.$router.push({ name: "login" });
    }
  }
};
</script>

<style scoped>
.login-part {
  width: 100%;
  height: 96vh;
}
.hover-link:hover {
  cursor: pointer;
}
</style>
